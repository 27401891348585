import React, { FC, useState } from "react";
import { useForm } from "react-hook-form";
import { ACTIONS, BASE_URL } from "../config/paths";
import { UserItemForm } from "../config/interfaces";
import { useNavigate } from "react-router-dom";
import axios from "axios";

interface AddEditUserFromProps {
	userDetail?: UserItemForm;
	edit?: boolean;
	id?: string;
}

const AddEditItemForm: FC<AddEditUserFromProps> = (props: AddEditUserFromProps) => {
	const userDetail = props.userDetail;
	const { register, handleSubmit } = useForm({ defaultValues: props.userDetail });
	const [errorMessage, setErrorMessage] = useState("");
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const userHash = sessionStorage.getItem("userHash");

	const updateData = (userData: UserItemForm) => {
		const formData = new FormData();

		for (const [key, value] of Object.entries(userData)) {
			formData.append(key, value);
		}

		formData.delete("_id");
		formData.delete("created");
		formData.delete("modified");

		axios
			.post(BASE_URL, formData, {
				headers: {
					Authorization: `Bearer ${userHash}`,
					"Content-Type": "multipart/form-data",
				},
				params: {
					action: props.edit ? ACTIONS.edit : ACTIONS.add,
					id: props.edit ? props.id : null,
				},
			})
			.then(function (response: any) {
				console.log(response.data);
				navigate("/");
			})
			.catch(function (error: any) {
				console.log(error);
				setErrorMessage(error.response.data.error);
			})
			.then(function () {
				setLoading(false);
			});
	};

	const onSubmit = (data: any) => {
		setLoading(true);
		updateData(data);
		console.log(data);
	};

	return (
		<>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="field required">
					<input id="inputName" className="input" placeholder=" " type="text" defaultValue={userDetail?.name} required {...register("name", { required: true })} />
					<label htmlFor="inputName" className="label">
						Jméno uživatele
					</label>
				</div>
				<div className="field required">
					<input id="inputCode" className="input" placeholder=" " type="text" defaultValue={userDetail?.code} required {...register("code", { required: true })} />
					<label htmlFor="inputCode" className="label">
						SMS kód
					</label>
				</div>
				<div className="field">
					<input id="inputDescription" className="input" placeholder=" " type="text" defaultValue={userDetail?.description} {...register("description")} />
					<label htmlFor="inputDescription" className="label">
						Poznámka
					</label>
				</div>
				<div className="field field-checkbox">
					<input id="inputIsActive" className="checkbox" type="checkbox" defaultChecked={userDetail?.isActive} {...register("isActive")} />
					<label htmlFor="inputIsActive" className="label-checkbox">
						Aktivní
					</label>
				</div>
				<div className="field">
					<input id="inputPosition" className="input" placeholder=" " type="number" min="0" defaultValue={userDetail?.position} {...register("position")} />
					<label htmlFor="inputPosition" className="label">
						Pozice
					</label>
				</div>
				<button type="submit" className="button">
					{props.edit ? "Upravit" : "Přidat"}
				</button>
			</form>
			{errorMessage}
			{loading && <p>Načítám...</p>}
		</>
	);
};

export default AddEditItemForm;
