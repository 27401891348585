// export const BASE_URL = "http://213.192.16.51:9039/";
// export const BASE_URL = "https://nulk.loraosvetleni.eu:8040/";
export const BASE_URL = process.env.REACT_APP_BASE_URL ?? "https://localhost:9040/";

export const ACTIONS = {
	add: "add",
	edit: "edit",
	detail: "detail",
	delete: "delete",
	numbers: "numbers",
	start: "start",
	stop: "stop",
	reset: "reset",
	login: "login",
	list: "list",
	smsGate: "sms-gate",
};
