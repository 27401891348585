import React, { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ACTIONS, BASE_URL } from "../config/paths";
import { SmsVoteInfo } from "../config/interfaces";
import axios from "axios";
import styles from "../styles/pages/OverviewTable.module.scss";

// noinspection JSUnusedLocalSymbols
const OverviewTable: FC = (props) => {
	const [smsData, setSmsData] = useState<SmsVoteInfo | null>(null);
	const [loading, setLoading] = useState(false);
	const userHash = sessionStorage.getItem("userHash");
	const [isSort, setIsSort] = useState(false);

	const getData = (sort: boolean = false) => {
		axios
			.get(BASE_URL, {
				headers: {
					Authorization: `Bearer ${userHash}`,
					"Content-Type": "application/json",
				},
				params: {
					action: ACTIONS.list,
					sort: sort ? "count" : null,
				},
			})
			.then(function (response: any) {
				// console.log(response.data);
				setSmsData(response.data);
			})
			.catch(function (error: any) {
				console.log(error);
				alert(error.message);
			})
			.then(function () {
				// always executed
			});
	};

	const onSort = (state: boolean) => {
		setLoading(true);
		setIsSort(state);
		getData(state);
		setLoading(false);
	};

	const onStart = () => {
		setLoading(true);

		axios
			.get(BASE_URL, {
				headers: {
					Authorization: `Bearer ${userHash}`,
					"Content-Type": "application/json",
				},
				params: {
					action: ACTIONS.start,
				},
			})
			.then(function (response: any) {
				console.log(response.data);
				getData();
			})
			.catch(function (error: any) {
				console.log(error);
			})
			.then(function () {
				// always executed
				setLoading(false);
			});
	};

	const onStop = () => {
		setLoading(true);

		axios
			.get(BASE_URL, {
				headers: {
					Authorization: `Bearer ${userHash}`,
					"Content-Type": "application/json",
				},
				params: {
					action: ACTIONS.stop,
				},
			})
			.then(function (response: any) {
				console.log(response.data);
				getData();
			})
			.catch(function (error: any) {
				console.log(error);
			})
			.then(function () {
				// always executed
				setLoading(false);
			});
	};

	const onReset = () => {
		setLoading(true);

		axios
			.get(BASE_URL, {
				headers: {
					Authorization: `Bearer ${userHash}`,
					"Content-Type": "application/json",
				},
				params: {
					action: ACTIONS.reset,
				},
			})
			.then(function (response: any) {
				console.log(response.data);
				getData();
			})
			.catch(function (error: any) {
				console.log(error);
			})
			.then(function () {
				// always executed
				setLoading(false);
			});
	};

	const onDeleteUser = (id: string) => {
		setLoading(true);

		axios
			.delete(BASE_URL, {
				headers: {
					Authorization: `Bearer ${userHash}`,
					"Content-Type": "application/json",
				},
				params: {
					action: ACTIONS.delete,
					id: id,
				},
			})
			.then(function (response: any) {
				console.log(response.data);
				getData();
			})
			.catch(function (error: any) {
				console.log(error);
			})
			.then(function () {
				// always executed
				setLoading(false);
			});
	};

	useEffect(() => {
		getData();
		// automaticky refresh
		const interval = setInterval(getData, 60 * 1000);
		return () => clearInterval(interval);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getStartDate = (smsData: SmsVoteInfo) => {
		return "Začátek hlasování: " + (smsData.startFormat ? new Date(smsData.startFormat)?.toLocaleString("cs-CZ") : "chybí datum");
	};

	const getStopDate = (smsData: SmsVoteInfo) => {
		return "Konec hlasování: " + (smsData.stopFormat ? new Date(smsData.stopFormat)?.toLocaleString("cs-CZ") : "chybí datum");
	};

	return (
		<>
			{smsData ? (
				<>
					<div className={styles.overview}>
						{/*<div>
							<a href="https://www.nulk.cz/" title="Národní ústav lidové kultury" className="default-logo">
								<img src="https://www.nulk.cz/wp-content/uploads/2017/01/NULK_logo_2_cz-1.png" alt="Národní ústav lidové kultury"/>
							</a>
						</div>*/}
						<div className="mb-md">
							<h2 className="h3">Status hlasování: {smsData.state}</h2>
							{smsData.startFormat ? <h3 className="h5 mb-0">{getStartDate(smsData)}</h3> : null}
							{smsData.stopFormat ? <h3 className="h5 mb-0">{getStopDate(smsData)}</h3> : null}
						</div>
						<Link to="/add" className="button">
							Přidat uživatele
						</Link>
						<br className="media-print-hide" />
						<br className="media-print-hide" />
						<button
							disabled={loading}
							onClick={() => {
								if (window.confirm("Určitě chcete spustit hlasování?")) {
									onStart();
								}
							}}
							className="button button-small"
						>
							Start
						</button>
						&nbsp;
						<button
							disabled={loading}
							onClick={() => {
								if (window.confirm("Určitě chcete zastavit hlasování?")) {
									onStop();
								}
							}}
							className="button button-small"
						>
							Stop
						</button>
						&nbsp;
						<button
							disabled={loading}
							onClick={() => {
								if (window.confirm("Určitě chcete promazat hlasování?")) {
									onReset();
								}
							}}
							className="button button-small"
						>
							Reset
						</button>
						<br className="media-print-hide" />
						<br className="media-print-hide" />
						Přehled
						<table className="table">
							<thead>
								<tr>
									<th>SMS kód</th>
									<th>Počet hlasů</th>
									<th>Jméno uživatele</th>
									<th className="media-print-hide">Pozice</th>
									<th className="media-print-hide"></th>
								</tr>
							</thead>
							<tbody>
								{smsData.stat?.top?.map((item) => (
									<tr key={item._id}>
										<td>{item.code}</td>
										<td>{item.count}</td>
										<td>{item.name}</td>
										<td className="media-print-hide">{item.position}</td>
										<td className="center media-print-hide">
											<Link to={"/numbers/" + item._id} className="button button-small">
												Detail
											</Link>
										</td>
									</tr>
								))}
							</tbody>
						</table>
						Suma hlasů: {smsData.stat?.sum ?? 0} hlasů
						<br />
						<br />
						<div className="table-responsive">
							<table className="table">
								<thead>
									<tr>
										<th>SMS kód</th>
										<th>
											<button disabled={loading} onClick={() => onSort(!isSort)} className="button-text">
												Počet hlasů {isSort ? <>(&uarr;)</> : ""}
											</button>
										</th>
										<th>Jméno uživatele</th>
										<th>Popis</th>
										<th className="media-print-hide">Pozice</th>
										<th className="media-print-hide">Vytvořeno</th>
										<th className="media-print-hide">Upraveno</th>
										<th className="media-print-hide"></th>
										<th className="media-print-hide center">Stav</th>
									</tr>
								</thead>
								<tbody>
									{smsData.data?.map((item) => (
										<tr key={item._id}>
											<td>{item.code}</td>
											<td>{item.count}</td>
											<td>{item.name}</td>
											<td>{item.description}</td>
											<td className="media-print-hide">{item.position}</td>
											<td className="media-print-hide">{item.created ? new Date(item.created)?.toLocaleString("cs-CZ") : "-"}</td>
											<td className="media-print-hide">{item.modified ? new Date(item.modified)?.toLocaleString("cs-CZ") : "-"}</td>
											<td className="center media-print-hide">
												<Link to={"/numbers/" + item._id} className="button button-small">
													Detail
												</Link>
												<Link to={"/edit/" + item._id} className="button button-small">
													Upravit
												</Link>
												<button
													onClick={() => {
														if (window.confirm("Opravdu chcete smazat uživatele: " + item.name + "?")) {
															onDeleteUser(item._id);
														}
													}}
													className="button button-small"
												>
													smazat
												</button>
											</td>
											<td className="media-print-hide">
												<div className="field-checkbox field-checkbox-center">
													<input id="inputIsActive" className="checkbox" type="checkbox" defaultChecked={item.isActive} disabled />
													<label htmlFor="inputIsActive" className="label-checkbox"></label>
												</div>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</div>
				</>
			) : (
				<>
					<h2 className="text-center">Data se načítají..</h2>
				</>
			)}
			{loading && <p className="text-center">Načítám...</p>}
		</>
	);
};

export default OverviewTable;
