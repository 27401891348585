import React, { FC, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import AddEditItemForm from "../components/AddEditItemForm";
import { ACTIONS, BASE_URL } from "../config/paths";
import axios from "axios";
import styles from "../styles/pages/AddEditItem.module.scss";
import "../styles/components/typography.scss";
import { UserItem, UserItemForm } from "../config/interfaces";

interface AddEditUserProps {
	edit?: boolean;
}

const AddEditItem: FC<AddEditUserProps> = (props: AddEditUserProps) => {
	const initialValues = {
		_id: "",
		name: "",
		code: "",
		description: "",
		isActive: true,
		position: 0,
	};

	const { id } = useParams();

	const [userDetail, setUserDetail] = useState<UserItem | null>(null);
	const [loaded, setLoaded] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const userHash = sessionStorage.getItem("userHash");

	const getDetailData = () => {
		axios
			.get(BASE_URL, {
				headers: {
					Authorization: `Bearer ${userHash}`,
					"Content-Type": "application/json",
				},
				params: {
					action: ACTIONS.detail,
					id: id,
				},
			})
			.then(function (response: any) {
				console.log(response.data);
				setUserDetail(response.data);
			})
			.catch(function (error: any) {
				setErrorMessage(error.response.data.error);
			})
			.then(function () {
				setLoaded(true);
			});
	};

	useEffect(() => {
		if (props.edit) {
			getDetailData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<p className={styles.backlink}>
				<Link to="/" className="button">
					zpět
				</Link>
			</p>
			{!!errorMessage && <p>{errorMessage}</p>}

			{props.edit ? (
				loaded && (
					<>
						<div className={styles.wrap}>
							<h2>Upravit uživatele</h2>
							<AddEditItemForm userDetail={(userDetail as UserItemForm) || initialValues} id={userDetail?._id} edit={props.edit} />
						</div>
					</>
				)
			) : (
				<>
					<div className={styles.wrap}>
						<h2>Přidat uživatele</h2>
						<AddEditItemForm edit={props.edit} />
					</div>
				</>
			)}
		</>
	);
};

export default AddEditItem;
