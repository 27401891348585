import { FC, useContext } from "react";
import { useForm } from "react-hook-form";
import { AuthContext } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ACTIONS, BASE_URL } from "../config/paths";
import styles from "../styles/pages/LoginPage.module.scss";

type Inputs = {
	login: string;
	pass: string;
};

const LoginPage: FC = () => {
	const authContext = useContext(AuthContext);
	const navigate = useNavigate();

	const onSubmit = (data: Inputs) => {
		const formData = new FormData();

		for (const [key, value] of Object.entries(data)) {
			formData.append(key, value);
		}

		axios
			.post(BASE_URL, formData, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
				params: {
					action: ACTIONS.login,
				},
			})
			.then(function (response: any) {
				console.log(response.data);
				authContext.setAuthentication(response.data);
				navigate("/");
			})
			.catch(function (error: any) {
				console.log(error);
				navigate("/unauthorized");
			});
	};

	const { handleSubmit, register } = useForm<Inputs>();

	return (
		<>
			<main className="layout">
				<div className={styles.wrap}>
					<h1>SMS hlasování</h1>
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="field">
							<input id="inputLogin" className="input" placeholder=" " autoFocus {...register("login")} type="text" />
							<label htmlFor="inputLogin" className="label">
								Login
							</label>
						</div>
						<div className="field">
							<input id="inputPass" className="input" placeholder=" " {...register("pass")} type="password" />
							<label htmlFor="inputPass" className="label">
								Password
							</label>
						</div>
						<button type="submit" className="button">
							Login
						</button>
					</form>
				</div>
			</main>
		</>
	);
};

export default LoginPage;
