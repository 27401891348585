import { createContext, FC, useState } from "react";
import { LoginResponse } from "../config/interfaces";

interface AuthContextProps {
	userHash: string | null;
	authenticated: boolean;
	setAuthentication: (authenticationResponse: LoginResponse) => void;
	clearAuthentication: () => void;
}

export const AuthContext = createContext<AuthContextProps>({
	userHash: null,
	authenticated: false,
	setAuthentication: () => console.warn("Not inside authorization provider"),
	clearAuthentication: () => console.warn("Not inside authorization provider"),
});

type Props = {
	children?: React.ReactNode;
};

const ProvideAuth: FC<Props> = ({ children }) => {
	const [userHash, setUserHash] = useState<string | null>("");
	const [authenticated, setAuthenticated] = useState<boolean>(false);

	const setAuthentication = (authenticationResponse: LoginResponse) => {
		console.log("hash: ", authenticationResponse.hash);
		sessionStorage.setItem("userHash", authenticationResponse.hash);
		setUserHash(authenticationResponse.hash);
		setAuthenticated(true);
	};

	const clearAuthentication = () => {
		sessionStorage.removeItem("userHash");
		setAuthenticated(false);
	};

	const contextValue: AuthContextProps = {
		userHash: userHash,
		authenticated: authenticated,
		setAuthentication: setAuthentication,
		clearAuthentication: clearAuthentication,
	};

	return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

export default ProvideAuth;
