import { FC, useContext, useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import UnauthorizedPage from "../pages/UnauthorizedPage";
import OverviewTable from "../pages/OverviewTable";
import NumbersTable from "../pages/NumbersTable";
import AddEditItem from "../pages/AddEditItem";
import { AuthContext } from "../context/AuthContext";
import "../styles/base/utilities.scss";
import "../styles/base/header.scss";
import "../styles/base/main.scss";
import "../styles/components/tables.scss";
import axios from "axios";
import { ACTIONS, BASE_URL } from "../config/paths";
import { SmsGate } from "../config/interfaces";

const BasePage: FC = () => {
	const [data, setData] = useState<SmsGate | null>(null);

	const auth = useContext(AuthContext);
	const navigate = useNavigate();

	function onLogout() {
		auth.clearAuthentication();
		navigate("/");
	}

	const getData = () => {
		let userHash = sessionStorage.getItem("userHash");
		axios
			.get(BASE_URL, {
				headers: {
					Authorization: `Bearer ${userHash}`,
					"Content-Type": "application/json",
				},
				params: {
					action: ACTIONS.smsGate,
				},
			})
			.then(function (response: any) {
				// console.log(response.data);
				setData(response.data);
			})
			.catch(function (error: any) {
				console.log(error);
				alert(error.message);
			})
			.then(function () {
				// always executed
			});
	};

	useEffect(() => {
		getData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<div className="layout-authorized">
				<header className="header">
					<div>
						<h1>SMS hlasování</h1>
					</div>
					<div>
						{data && <button className="button button-medium button-reverse">{data.phoneNumber}</button>}
						&nbsp; &nbsp; &nbsp;
						<button onClick={onLogout} className="button button-medium button-reverse">
							Logout
						</button>
					</div>
				</header>
				<main className="main">
					<Routes>
						<Route
							path="/add"
							element={
								<PrivateRoute>
									<AddEditItem />
								</PrivateRoute>
							}
						/>
						<Route
							path="/edit/:id"
							element={
								<PrivateRoute>
									<AddEditItem edit />
								</PrivateRoute>
							}
						/>
						<Route
							path="/numbers/:id"
							element={
								<PrivateRoute>
									<NumbersTable />
								</PrivateRoute>
							}
						/>
						<Route
							path="*"
							element={
								<PrivateRoute>
									<OverviewTable />
								</PrivateRoute>
							}
						/>
						<Route path="/unauthorized" element={<UnauthorizedPage />} />
					</Routes>
				</main>
			</div>
		</>
	);
};

export default BasePage;
