import React from "react";
import { Route, Routes } from "react-router-dom";
import ProvideAuth from "./context/AuthContext";
import PrivateRoute from "./layout/PrivateRoute";
import LoginPage from "./pages/LoginPage";
import UnauthorizedPage from "./pages/UnauthorizedPage";
import BasePage from "./layout/BasePage";
import "./styles/base/layout.scss";
import "./styles/components/headings.scss";
import "./styles/components/inputs.scss";
import "./styles/components/buttons.scss";

function App() {
	return (
		<>
			<ProvideAuth>
				<Routes>
					<Route path="/login" element={<LoginPage />} />
					<Route path="/unauthorized" element={<UnauthorizedPage />} />
					<Route
						path="*"
						element={
							<PrivateRoute>
								<BasePage />
							</PrivateRoute>
						}
					/>
				</Routes>
			</ProvideAuth>
		</>
	);
}

export default App;
