import React, { FC, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { ACTIONS, BASE_URL } from "../config/paths";
import { SmsObject } from "../config/interfaces";
import axios from "axios";
import styles from "../styles/pages/NumbersTable.module.scss";

// noinspection JSUnusedLocalSymbols
const NumbersTable: FC = (props) => {
	const { id } = useParams();
	const userHash = sessionStorage.getItem("userHash");

	const [smsData, setSmsData] = useState<SmsObject | null>(null);

	const getData = () => {
		axios
			.get(BASE_URL, {
				headers: {
					Authorization: `Bearer ${userHash}`,
					"Content-Type": "application/json",
				},
				params: {
					action: ACTIONS.numbers,
					id: id,
				},
			})
			.then(function (response: any) {
				console.log(response.data);
				setSmsData(response.data);
			})
			.catch(function (error: any) {
				console.log(error);
			})
			.then(function () {
				// always executed
			});
	};

	useEffect(() => {
		getData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			{smsData ? (
				<>
					<div className={styles.numbers}>
						<p className={styles.backlink}>
							<Link to="/" className="button">
								zpět
							</Link>
						</p>
						<h2>
							Čísla pro kod: <strong>{smsData.code}</strong>
						</h2>
						<p>
							pocet cisel: {smsData.total}
							<br />
							pocet validnich: {smsData.validCount}
							<br />
							pocet sms: {smsData.smsCount}
							<br />
						</p>

						{smsData.data && smsData.data.length > 0 ? (
							<>
								<div className="table-responsive">
									<table className="table">
										<thead>
											<tr>
												<th>cislo</th>
												<th>aktivni datum</th>
												<th>posladni sms datum</th>
												<th>datumy odeslani</th>
												<th>pocet odeslani</th>
											</tr>
										</thead>
										<tbody>
											{smsData.data?.map((item, i) => (
												<tr key={i}>
													<td>{item.number}</td>
													<td>{item.activeDate ? new Date(item.activeDate)?.toLocaleString("cs-CZ") : "---"}</td>
													<td>{item.lastSend ? new Date(item.lastSend)?.toLocaleString("cs-CZ") : "---"}</td>
													<td>
														{item.createdList.map((value, i) => (
															<span key={i} style={{ color: value.state === "active" ? "green" : "red" }}>
																{new Date(value.date).toLocaleString("cs-CZ")},&nbsp;
															</span>
														))}
													</td>
													<td>{item.count}</td>
												</tr>
											))}
										</tbody>
									</table>
								</div>
							</>
						) : (
							<>
								<h2 className="text-center">Tabulka je prázdná</h2>
							</>
						)}
					</div>
				</>
			) : (
				<>
					<h2 className="text-center">Data se načítají..</h2>
				</>
			)}
		</>
	);
};

export default NumbersTable;
