import { FC, ReactElement } from "react";

const UnauthorizedPage: FC = (): ReactElement => {
	return (
		<>
			<main className="layout">
				<div>Access denied.</div>
			</main>
		</>
	);
};

export default UnauthorizedPage;
